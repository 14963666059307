import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { JOB, propTypes } from '../../util/types';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  ManageListingCard,
  Page,
  PaginationLinks,
  UserNav,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedRedirect,
  ManageJobCard
} from '../../components';
import { approvedUserStatus} from "../../util/userRole";
import { TopbarContainer } from '../../containers';
import { withRouter } from 'react-router-dom';
import { closeListing, openListing, getOwnListingsById } from './ManageListingsPage.duck';
import css from './ManageListingsPage.module.css';
import { updateListingPublicData } from '../../util/api';
import { LISTING_TYPE_FIRM } from '../../util/types'
import { getListingsById } from '../../ducks/marketplaceData.duck';


const ManageListingsPageComponent = (props) => {
  
  const {
    closingListing,
    closingListingError,
    listings,
    onCloseListing,
    onOpenListing,
    openingListing,
    openingListingError,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    scrollingDisabled,
    intl,
    currentUser,
    params,
    reviews
  } = props;
  const [listingMenuOpen, setListingMenuOpen] = useState(null);
  const [inviteSuccessToaster, setInviteSuccessToaster] = useState(false);

  useEffect(() => {
    try {
      const userProfile = currentUser?.attributes?.profile?.publicData;
      const firmName = userProfile?.firmListing?.firmTitle ?? userProfile?.linkedToFirms?.[0]?.firmName;

      const reviewKeyword = reviews?.length
        ? reviews.map((review) => review.attributes.content).join() : '';

      for (const listing of listings) {
        const { publicData } = listing.attributes;
        const {
          type,
          locations,
          packageKeyword,
          industrySector,
          domainExpertise,
        } = publicData;

        if (type === LISTING_TYPE_FIRM) continue;

        const locationToUse = locations?.[0]?.Location?.search;

        const searchFilters = [
          firmName,
          industrySector?.join(','),
          domainExpertise?.join(','),
          reviewKeyword,
          locationToUse,
        ].filter(Boolean); // Filter out empty values

        const shouldUpdate = searchFilters.some((filter) => !packageKeyword?.includes(filter));

        if (shouldUpdate) {
          const updatedPackageKeyword = packageKeyword
            ? packageKeyword.concat(searchFilters.join(','))
            : searchFilters.join(',');

          const listingUpdateParam = {
            id: listing.id,
            publicData: {
              packageKeyword: updatedPackageKeyword,
            },
          };
          // updateListingPublicData(listingUpdateParam);
        }
      }
    } catch (error) {
      console.error('Error in componentDidUpdate:', error);
    }
  }, [listings?.length])
  
  useEffect(() => {
    if (inviteSuccessToaster) {
      const timer = setTimeout(() => {
        setInviteSuccessToaster(false);
      }, 5000);

      // Clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [inviteSuccessToaster]);

  const isApprovedUser = approvedUserStatus(currentUser);
  const {listingType} = params;
  const isJobListing = listingType === JOB;

  if (!!currentUser && !isApprovedUser) {
    return <NamedRedirect name="LandingPage" />;
  }

  const hasPaginationInfo = !!pagination && pagination.totalItems != null;
  const listingsAreLoaded = !queryInProgress && hasPaginationInfo;

  
  const listingsBriefs = listings.filter(item=> item.attributes.publicData.listingType === listingType);

  const loadingResults = (
    <h2 className={css.title}>
      <FormattedMessage id={isJobListing ? "ManageListingsPage.loadingJobDescription" : "ManageListingsPage.loadingOwnListings"} />
    </h2>
  );

  const queryError = (
    <h2 className={css.error}>
      <FormattedMessage id="ManageListingsPage.queryError" />
    </h2>
  );

  const noResults =
    listingsAreLoaded && listingsBriefs.length === 0 ? (
      <h1 className={css.title}>
        <FormattedMessage id={isJobListing ? "ManageListingsPage.noJobResults" : "ManageListingsPage.noResults"} />
      </h1>
    ) : null;

  const heading =
    listingsAreLoaded && listingsBriefs.length > 0 ? (
      <h1 className={css.title}>
        <FormattedMessage
          id={isJobListing ? "ManageListingsPage.jobListings" : "ManageListingsPage.youHaveListings"}
          values={{ count: listingsBriefs.length }}
        />
      </h1>
    ) : (
      noResults
    );

  const page = queryParams ? queryParams.page : 1;
  const paginationLinks =
    listingsAreLoaded && pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="ManageListingsPage"
        pageSearchParams={{ page }}
        pagination={pagination}
        pagePathParams={params}
      />
    ) : null;

  const closingErrorListingId = !!closingListingError && closingListingError.listingId;
  const openingErrorListingId = !!openingListingError && openingListingError.listingId;

  const title = intl.formatMessage({ id: isJobListing ? 'TopbarDesktop.manageJobDescription' : 'TopbarDesktop.viewListing' });

  const panelWidth = 62.5;
  // Render hints for responsive image
  const renderSizes = [
    `(max-width: 767px) 100vw`,
    `(max-width: 1920px) ${panelWidth / 2}vw`,
    `${panelWidth / 3}vw`,
  ].join(', ');

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="ManageListingsPage" />
          {/* <UserNav selectedPageName="ManageListingsPage" /> */}
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          {queryInProgress ? loadingResults : null}
          {queryListingsError ? queryError : null}
          <div className={css.listingPanel}>
            {inviteSuccessToaster && (
              <div className={css.successMessage}>
                <FormattedMessage id="ManageListingCard.inviteSuccessToasterText" />
              </div>
            )}
            {heading}
            <div className={css.listingCards}>
              {listingsBriefs.map(l => isJobListing ? (
                <ManageJobCard 
                  className={css.listingCard}
                  key={l.id.uuid}
                  listing={l}
                  actionsInProgressListingId={openingListing || closingListing}
                  onCloseListing={onCloseListing}
                  onOpenListing={onOpenListing}
                />
              ) : (
                <ManageListingCard
                  className={css.listingCard}
                  key={l.id.uuid}
                  listing={l}
                  isMenuOpen={!!listingMenuOpen && listingMenuOpen.id.uuid === l.id.uuid}
                  actionsInProgressListingId={openingListing || closingListing}
                  onToggleMenu={(value) => setListingMenuOpen(value)}
                  onCloseListing={onCloseListing}
                  onOpenListing={onOpenListing}
                  hasOpeningError={openingErrorListingId.uuid === l.id.uuid}
                  hasClosingError={closingErrorListingId.uuid === l.id.uuid}
                  renderSizes={renderSizes}
                  setInviteSuccessToaster={() => setInviteSuccessToaster(true)}
                  listingMenuOpen={listingMenuOpen}
                />
              ))}
            </div>
            {paginationLinks}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
}

ManageListingsPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  queryListingsError: null,
  queryParams: null,
  closingListing: null,
  closingListingError: null,
  openingListing: null,
  openingListingError: null,
};

const { arrayOf, bool, func, object, shape, string } = PropTypes;

ManageListingsPageComponent.propTypes = {
  closingListing: shape({ uuid: string.isRequired }),
  closingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  // listings: arrayOf(propTypes.ownListing),
  onCloseListing: func.isRequired,
  onOpenListing: func.isRequired,
  openingListing: shape({ uuid: string.isRequired }),
  openingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  pagination: propTypes.pagination,
  queryInProgress: bool.isRequired,
  queryListingsError: propTypes.error,
  queryParams: object,
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
    reviews,
    sharedListings
  } = state.ManageListingsPage;
  const { currentUser } = state.user;
  const listings = getOwnListingsById(state, currentPageResultIds);
  // const combinedListings = [...listings,...sharedListings]
  return {
    currentPageResultIds,
    listings,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    scrollingDisabled: isScrollingDisabled(state),
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
    currentUser,
    reviews
  };
};

const mapDispatchToProps = dispatch => ({
  onCloseListing: listingId => dispatch(closeListing(listingId)),
  onOpenListing: listingId => dispatch(openListing(listingId)),
});

const ManageListingsPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ManageListingsPageComponent);

export default ManageListingsPage;
